<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-app-bar app clipped-right color="primary">
      <h1>The Council</h1>
      <v-spacer></v-spacer>
      <v-btn v-show="isConnected"
        red
        elevation="2"
        @click="close_api"
        >Disconnect</v-btn>
      <v-btn v-show="!isConnected"
        green
        elevation="2"
        @click="open_api"
        >Connect</v-btn>
      <v-icon
          @click.stop="drawer = !drawer"
        >mdi-menu-open</v-icon>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        :color="color"
        :expand-on-hover="expandOnHover"
        :mini-variant="miniVariant"
        :right="right"
        :clipped="clipped"
        app
        absolute
        dark
      >
      <v-list
        dense
        nav
        class="py-0"
      >
        <!--<v-list-item two-line :class="miniVariant && 'px-0'">-->
        <!--  <v-list-item-avatar>-->
        <!--    <img src="https://randomuser.me/api/portraits/men/81.jpg">-->
        <!--  </v-list-item-avatar>-->

        <!--  <v-list-item-content>-->
        <!--    <v-list-item-title>Application</v-list-item-title>-->
        <!--    <v-list-item-subtitle>Subtext</v-list-item-subtitle>-->
        <!--  </v-list-item-content>-->
        <!--</v-list-item>-->

        <v-divider></v-divider>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main app color="secondary">
      <Display />
    </v-main>
    <v-footer app color="primary">
      <h4>Just a tech demo.</h4>
      <v-spacer></v-spacer>
      <h4>&copy;2020</h4>
    </v-footer>
  </v-app>
</template>

<script>
import Display from './components/Display.vue';
import {mapActions, mapState} from 'vuex'

export default {
  name: 'App',

  components: {
    Display
  },
  data () {
    return {
      drawer: false,
      items: [
        { title: 'Home', icon: 'mdi-home' },
        { title: 'Play Game', icon: 'mdi-play-arrow' },
        { title: 'About', icon: 'mdi-help-box' },
        { title: 'Connect', icon: 'mdi-lan-connect' }
      ],
      color: 'primary',
      colors: [
        'primary',
        'blue',
        'success',
        'red',
        'teal',
      ],
      right: true,
      clipped: true,
      miniVariant: false,
      expandOnHover: false,
      background: false,
    }
  },
  computed: mapState({
    isConnected: state => state.socket.isConnected,
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },
  }),
  methods: {
    ...mapActions([
      'open_api',
      'close_api',
      'register_connection'
      ])
  },
  watch: {
    isConnected(newValue) {
      if (newValue == true) {
        console.log("Sending session to new connection.");
        this.$store.dispatch('register_connection');
      }
    }
  }
};
</script>

<style scoped>
</style>