<template>
  <div class="resource">
    <p>
      <span class="resource_label">{{ resource.label }}</span>
      <span @click="increment(resource_id)" class="resource_box" style="">
        <span class="resource_bar" :style="barStyle"></span>
      </span>
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Resource',
  props: {
    resource: Object,
    resource_id: String
  },
  computed: {
    barColor() {
      if (this.resource.quantity < 26){
        return "red"
      } else if (this.resource.quantity > 74) {
        return "green"
      } else {
        return "grey"
      }
    },
    barStyle() {
      return {
        width: `${this.resource.quantity}%`,
        "background-color": `${this.barColor}`
      }
    }
  },
  methods: {
    ...mapActions([
      'increment',
      'decrement'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.resource_box {
  display: block;
  margin: 0 auto;
  border: 1px solid grey;
  background-color: white;
  width:315px;
  height:16px;
}
.resource_bar {
  display: block;
  height:14px;
  transition: width 0.5s;
}
.resource_label {
  
}
</style>
