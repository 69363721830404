<template>
  <v-container width="350" color="secondary">
    <v-container align="center">
      <v-row justify="center" no-gutters v-for="(item,id) in this.resources" :key="id">
        <Resource :key="id" :resource_id="id" :resource="item"></Resource>
      </v-row>
    </v-container>
    <v-row color="secondary">
      <Scene v-for="(item,id) in this.scenes" :key="id" :scene="{...item, id}"/>
    </v-row>  
  </v-container>
</template>

<script>
import Resource from './Resource.vue'
import Scene from './Scene.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Display',
  mounted () {
    // this.startScene({'nextScene':'scene1'})
  },
  components: {
    Resource,
    Scene
  },
  props: {
  },
  computed: {
    ...mapState({
      resources: state => state.game_state.resources,
      scenes: state => state.game_state.active_scenes,
    })
    
  },
  methods: {
    ...mapActions([
      'startScene'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
