import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },
    user: {
      user_id: "SHITHEAD"
    },
    game_id: "DEADBEEF",
    game_state: {
      active_scenes: {
        testScene: {
          title: "It Begins",
          body: "Hunger wracks the city. A mass of beggars swarmmed a stall, stealing every scrap of food possible before scattering to the alleyways. The stall owner demands to be compensated. Should we reimburse him the cost of the stolen food and damage to his stall?",
          locked: false,
          buttons: {
            no: {
              button_id: "no",
              label: "no",
              scene_id: "testScene",
              selected: false,
              confirmed: false
            },
            yes: {
              button_id: "yes",
              label: "yes",
              scene_id: "testScene",
              selected: false,
              confirmed: false
            },
            pass: {
              button_id: "pass",
              label: "pass",
              scene_id: "testScene",
              selected: false,
              confirmed: false
            }
          },
          selections: {
            1: {
              
            }
          }
        }
      },
      resources: {}
    }
  },
  mutations: {
    SOCKET_ONOPEN(state) {
      state.socket.isConnected = true;
      console.log('connected');
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
      console.log('disconnected');
    },
    SOCKET_RECONNECT() {
      console.log('reconnecting...');
    },
    SOCKET_ONERROR() {
      console.log('some error');
    },
    SOCKET_ONMESSAGE(state, message) {
      console.log(message.message)
      state.game_state = message.game_state;
      state.socket.message = message.message;
    },
    send_message(state, payload) {
      console.log("Sending message.");
      let message = {
        user_id: state.user.user_id,
        game_id: state.game_id,
        action: payload.action,
        scene_id: payload.id,
        payload: payload
      };
      console.log("Sending " + JSON.stringify(message));
      Vue.prototype.$socket.sendObj(message);
    },
    lock_scene(state, scene_id) {
      state.game_state.active_scenes[scene_id].locked = true;
      // state.game_state.active_scenes[payload.scene_id].buttons[payload.button_id].confirmed = state.game_state.active_scenes[payload.scene_id].buttons[payload.button_id].selected;
      // state.game_state.active_scenes[payload.scene_id].buttons[payload.button_id].selected = !state.game_state.active_scenes[payload.scene_id].buttons[payload.button_id].selected;
      // state.game_state.active_scenes[payload.scene_id].confirmed = state.game_state.active_scenes[payload.scene_id].buttons[payload.button_id].confirmed;
    },
    clear_lock(state, scene_id) {
      console.log("Clearing lock " + scene_id);
      state.game_state.active_scenes[scene_id].locked = false;
    }
  },
  actions: {
    trigger_activity(context, {scene, label}) {
      console.log(label);
      let activity = scene;
      activity.label = label;
      activity.game_id = context.get_game_id;
      activity.user_id = context.get_user_id;
      console.log("Locking scene " + activity.id);
      context.commit("lock_scene", activity.id);
      console.log("Triggering activity " + activity.label);
      activity['action'] = 'activity';
      context.commit("send_message", activity);
      // {
      //   'game_id': '1',
      //   'scene_id': '1',
      //   'scene_id:game_id': '1:1',
      //   'user_id': '1',
      //   'label': 'YES'
      // }
    },
    ack(context, payload) {
      console.log("Received ack from server");
      console.log(payload);
      context.commit("clear_lock", payload.activity.scene_id);
    },
    open_api() {
      // TODO: Check if already connected
      console.log("Attempting to connect...");
      Vue.prototype.$connect();
    },
    close_api() {
      console.log("Disconnecting...");
      Vue.prototype.$disconnect();
    },
    register_connection(context) {
      let message = {
        action: 'register',
        game_id: context.getters.get_game_id,
        user_id: context.getters.get_user_id
      }
      context.commit('send_message', message);
    }
  },
  getters: {
    get_game_id: state => {
      return state.game_id
    },
    get_user_id: state => {
      return state.user.user_id
    }
  }
});

export default store;