<template>
  <transition name="component-fade" mode="out-in">
    <v-card 
      class="mx-auto scene_card"
      >
      <v-card-title>{{ scene.title }}</v-card-title>
      <v-card-text>{{ scene.body }}</v-card-text>
      <v-card-actions class="mx-auto">
        <v-row>
          <v-col class="no_buttons">
            <v-btn v-if="scene.buttons.no" 
              v-on:click="trigger_activity(
                { scene: scene,
                  label: scene.buttons.no.label
                  })"
              :disabled="no_disabled"
              :depressed="scene.buttons.no.depressed"
              class="vote_button"
              >
                {{scene.buttons.no.label}}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn v-if="scene.buttons.pass" 
              v-on:click="trigger_activity(
                { scene: scene,
                  label: scene.buttons.pass.label
                  })"
              :disabled="pass_disabled"
              class="vote_button"
              >
                {{scene.buttons.pass.label}}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn v-if="scene.buttons.yes" 
              v-on:click="trigger_activity(
                { scene: scene,
                  label: scene.buttons.yes.label
                  })"
              :disabled="yes_disabled"
              class="vote_button"
              v-bind:class="{'yes-selected': scene.buttons.yes.selected, 'yes-confirmed': scene.buttons.yes.confirmed}"
              >
                {{scene.buttons.yes.label}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Scene',
  props: {
    scene: Object,
    id: String
  },
  computed: {
    ...mapState({
      user_id: state => state.user.user_id
    }),
    no_disabled() {
      // 
      // (this.scene.selections[this.user_id].locked || this.scene.selections[this.user_id].confirmed)
      if (this.scene.locked || this.scene.confirmed) {
        return true;
      } else {
        return false;
      }
    },
    yes_disabled() {
      if (this.scene.locked || this.scene.confirmed) {
        return true;
      } else {
        return false;
      }
    },
    pass_disabled() {
      if (this.scene.locked || this.scene.confirmed) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions([
      'trigger_activity'
    ])
  },
  mounted () {
    if (this.scene.onLoad) {
      this.scene.onLoad.forEach(element => this.sceneLoad(element));
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.resource_box {
  display: block;
  margin: 0 auto;
  border: 1px solid grey;
  width:400px;
  height:20px;
}
.resource_bar {
  display: block;
  height:20px;
}
.scene_card {
  max-width: 425px;
}
.vote_button {
  width: 100px;
  margin: 0 auto;
}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.yes-selected {
  background-color: green;
    background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.75) 51%);
}

.yes-confirmed {
  background-color: green;
}
</style>
