import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'md',
    theme: {
        themes: {
            light: {
                primary: "#DEB887",
                secondary: "#F5DEB3",
                accent: "#2196f3",
                error: "#f44336",
                warning: "#795548",
                info: "#03a9f4",
                success: "#4caf50",
                background: "#FFF8DC"
            }
        }
    }
});
