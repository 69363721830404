import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c(VCard,{staticClass:"mx-auto scene_card"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.scene.title))]),_c(VCardText,[_vm._v(_vm._s(_vm.scene.body))]),_c(VCardActions,{staticClass:"mx-auto"},[_c(VRow,[_c(VCol,{staticClass:"no_buttons"},[(_vm.scene.buttons.no)?_c(VBtn,{staticClass:"vote_button",attrs:{"disabled":_vm.no_disabled,"depressed":_vm.scene.buttons.no.depressed},on:{"click":function($event){return _vm.trigger_activity(
              { scene: _vm.scene,
                label: _vm.scene.buttons.no.label
                })}}},[_vm._v(" "+_vm._s(_vm.scene.buttons.no.label)+" ")]):_vm._e()],1),_c(VCol,[(_vm.scene.buttons.pass)?_c(VBtn,{staticClass:"vote_button",attrs:{"disabled":_vm.pass_disabled},on:{"click":function($event){return _vm.trigger_activity(
              { scene: _vm.scene,
                label: _vm.scene.buttons.pass.label
                })}}},[_vm._v(" "+_vm._s(_vm.scene.buttons.pass.label)+" ")]):_vm._e()],1),_c(VCol,[(_vm.scene.buttons.yes)?_c(VBtn,{staticClass:"vote_button",class:{'yes-selected': _vm.scene.buttons.yes.selected, 'yes-confirmed': _vm.scene.buttons.yes.confirmed},attrs:{"disabled":_vm.yes_disabled},on:{"click":function($event){return _vm.trigger_activity(
              { scene: _vm.scene,
                label: _vm.scene.buttons.yes.label
                })}}},[_vm._v(" "+_vm._s(_vm.scene.buttons.yes.label)+" ")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }