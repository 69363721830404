import Vue from 'vue'
import VueNativeSock from "vue-native-websocket";
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store.js';

// var scenes = require('./game_content/scenes.js');

Vue.config.productionTip = false
Vue.use(VueNativeSock, "wss://game-api.levithecloudguy.com/", { 
  connectManually: true,
  reconnection: true,
  reconnectionAttempts: 10,
  store: store, 
  format: 'json'
});

Vue.use(Vuex)

new Vue({
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
