import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{style:({background: _vm.$vuetify.theme.themes[_vm.theme].background})},[_c(VAppBar,{attrs:{"app":"","clipped-right":"","color":"primary"}},[_c('h1',[_vm._v("The Council")]),_c(VSpacer),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.isConnected),expression:"isConnected"}],attrs:{"red":"","elevation":"2"},on:{"click":_vm.close_api}},[_vm._v("Disconnect")]),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isConnected),expression:"!isConnected"}],attrs:{"green":"","elevation":"2"},on:{"click":_vm.open_api}},[_vm._v("Connect")]),_c(VIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v("mdi-menu-open")])],1),_c(VNavigationDrawer,{attrs:{"color":_vm.color,"expand-on-hover":_vm.expandOnHover,"mini-variant":_vm.miniVariant,"right":_vm.right,"clipped":_vm.clipped,"app":"","absolute":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c(VDivider),_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"link":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)})],2)],1),_c(VMain,{attrs:{"app":"","color":"secondary"}},[_c('Display')],1),_c(VFooter,{attrs:{"app":"","color":"primary"}},[_c('h4',[_vm._v("Just a tech demo.")]),_c(VSpacer),_c('h4',[_vm._v("©2020")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }